import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Import user context
import Navbar from "./Navbar"; // Import reusable Navbar component
import "./Homepage.css";

const Homepage = () => {
  const { user } = useUser(); // Get user from context
  const navigate = useNavigate();

  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch("/api/fetchNews");
        if (!response.ok) {
          throw new Error("Failed to fetch news articles");
        }
        const data = await response.json();
        setArticles(data.articles); // Set fetched articles
      } catch (error) {
        console.error("Error fetching news:", error);
        setErrorMessage("Failed to load news articles."); // Set error message
      } finally {
        setLoading(false); // Set loading state to false
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="homepage-container">
      {/* Navbar Section */}
      <Navbar />

      {/* Hero Section */}
      <div className="hero-section">
        <div className="video-overlay">
          <video autoPlay loop muted playsInline className="background-video">
            <source
              src="https://videos.pexels.com/video-files/5182640/5182640-uhd_2560_1440_25fps.mp4"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1>Welcome to MilkySports</h1>
            <p>Your go-to platform for fantasy sports!</p>
            <button className="button" onClick={() => navigate("/fantasy")}>
              Get Started
            </button>
          </div>
        </div>
      </div>

      {/* News Section */}
      <div className="news-section">
        <h2>Recent News</h2>
        {loading ? (
          <p>Loading sports news...</p>
        ) : errorMessage ? (
          <p style={{ color: "red" }}>{errorMessage}</p>
        ) : (
          <div className="news-cards">
            {articles.slice(0, 5).map((article, index) => (
              <div className="news-card" key={index}>
                {article.image && (
                  <img
                    src={article.image}
                    alt={article.title}
                    className="news-image"
                  />
                )}
                <div className="news-content">
                  <h3 className="news-title">{article.title}</h3>
                  <p className="news-description">{article.description}</p>
                  <a
                    href={article.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="news-link"
                  >
                    Read more
                  </a>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Homepage;

import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext";
import "./Navbar.css";

const Navbar = () => {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState({});
  const [userMenuOpen, setUserMenuOpen] = useState(false); // State for user dropdown menu
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleDropdown = (key, isOpen) => {
    setDropdownOpen((prev) => ({ ...prev, [key]: isOpen }));
  };

  const sports = [
    { name: "NFL", links: ["Teams", "Scores", "Standings"], path: "/nfl" },
    { name: "NBA", links: ["Teams", "Scores", "Standings"], path: "/nba" },
    { name: "MLB", links: ["Teams", "Scores", "Standings"], path: "/mlb" },
    { name: "NHL", links: ["Teams", "Scores", "Standings"], path: "/nhl" },
    { name: "Soccer", links: ["Teams", "Scores", "Standings"], path: "/soccer" },
    { name: "NCAA", links: ["Teams", "Scores", "Standings"], path: "/ncaa" },
    {
      name: "Fantasy",
      links: ["Draft", "Tips", "Leagues", "Create Your Organization"],
      path: "/fantasy",
    },
  ];

  const handleLogout = () => {
    setUser(null);
    navigate("/login");
  };

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="logo" onClick={() => navigate("/")}>
          MILKYSPORTS
        </div>
        <button
          className="mobile-menu-toggle"
          onClick={() => setMobileMenuOpen((prev) => !prev)}
        >
          ☰
        </button>
        <ul className={`sports-links ${mobileMenuOpen ? "active" : ""}`}>
          {sports.map((sport) => (
            <li
              key={sport.name}
              className="dropdown"
              onMouseEnter={() => toggleDropdown(sport.name, true)}
              onMouseLeave={() => toggleDropdown(sport.name, false)}
            >
              <button
                className="dropdown-button"
                onClick={() => navigate(sport.path || `/${sport.name.toLowerCase()}`)}
              >
                {sport.name}
              </button>
              {dropdownOpen[sport.name] && (
                <div className="dropdown-menu">
                  <h4>{sport.name} Options</h4>
                  <ul>
                    {sport.links.map((link) => (
                      <li key={link}>
                        <Link
                          to={
                            link === "Create Your Organization"
                              ? "/create-organization"
                              : `/${sport.name.toLowerCase()}/${link.toLowerCase()}`
                          }
                        >
                          {link}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="auth-buttons">
          {user ? (
            <div
              className="user-menu"
              onMouseEnter={() => setUserMenuOpen(true)}
              onMouseLeave={() => setUserMenuOpen(false)}
            >
              <span className="username">Welcome, {user.username}</span>
              <div className={`user-dropdown ${userMenuOpen ? "active" : ""}`}>
                <ul>
                  <li>
                    <button onClick={() => navigate("/profile")}>Profile</button>
                  </li>
                  <li>
                    <button onClick={() => navigate("/settings")}>Settings</button>
                  </li>
                  <li>
                    <button onClick={handleLogout}>Log out</button>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            <>
              <button className="login-button" onClick={() => navigate("/login")}>
                Log in
              </button>
              <button className="signup-button" onClick={() => navigate("/create-account")}>
                Sign up
              </button>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

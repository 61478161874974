import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "./UserContext"; // Import the user context
import { FiHome, FiSettings, FiUsers, FiFileText, FiPlus } from "react-icons/fi"; // Icons
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { createClient } from "@supabase/supabase-js"; // Import Supabase client
import CreateFantasyLeague from "./CreateFantasyLeague"; // Import CreateFantasyLeague component
import CreateOrganization from "./CreateOrganization"; // Import CreateOrganization component
import OrganizationManagement from "./OrganizationManagement"; // Import OrganizationManagement component
import FantasyLeagueManagement from "./FantasyLeagueManagement"; // Import FantasyLeagueManagement component
import "./Fantasy.css";

// Initialize Supabase client
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const Fantasy = () => {
  const { user, setUser } = useUser(); // Get user and setUser from context
  const navigate = useNavigate(); // Use React Router for navigation

  const handleLogout = () => {
    setUser(null); // Clear user from context
    navigate("/login"); // Redirect to login page
  };

  const [openMenus, setOpenMenus] = useState({}); // Allow multiple open menus
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [leaguesBySport, setLeaguesBySport] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [showCreateLeague, setShowCreateLeague] = useState(false);
  const [showCreateOrganization, setShowCreateOrganization] = useState(false);

  // Fetch leagues and organizations from the database
  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          // Fetch leagues where user is the owner
          const { data: ownerLeagues, error: ownerLeaguesError } = await supabase
            .from("fantasy_leagues")
            .select(`
              *,
              sports ( sport_name )
            `)
            .eq("owner_id", user.id);

          if (ownerLeaguesError) throw ownerLeaguesError;

          // Fetch league_ids where user is a member
          const { data: leagueMemberships, error: leagueMembershipsError } = await supabase
            .from("league_members")
            .select("league_id")
            .eq("user_id", user.id);

          if (leagueMembershipsError) throw leagueMembershipsError;

          const memberLeagueIds = leagueMemberships.map((membership) => membership.league_id);

          // Fetch leagues where user is a member
          let memberLeagues = [];
          if (memberLeagueIds.length > 0) {
            const { data: memberLeaguesData, error: memberLeaguesError } = await supabase
              .from("fantasy_leagues")
              .select(`
                *,
                sports ( sport_name )
              `)
              .in("league_id", memberLeagueIds);

            if (memberLeaguesError) throw memberLeaguesError;
            memberLeagues = memberLeaguesData;
          }

          // Combine ownerLeagues and memberLeagues
          const allLeagues = [...ownerLeagues, ...memberLeagues];

          // Remove duplicates based on league_id
          const allLeaguesMap = {};
          allLeagues.forEach((league) => {
            allLeaguesMap[league.league_id] = league;
          });
          const uniqueLeagues = Object.values(allLeaguesMap);

          // Group leagues by sport
          const groupedLeagues = uniqueLeagues.reduce((acc, league) => {
            const sportName = league.sports?.sport_name || "Unknown Sport";
            if (!acc[sportName]) acc[sportName] = [];
            acc[sportName].push(league);
            return acc;
          }, {});

          setLeaguesBySport(groupedLeagues);

          // Fetch user's organizations
          const { data: ownedOrgs, error: ownedOrgsError } = await supabase
            .from("organizations")
            .select("*")
            .eq("owner_id", user.id);

          if (ownedOrgsError) throw ownedOrgsError;

          const { data: memberOrgs, error: memberOrgsError } = await supabase
            .from("orgmembers")
            .select("organization_id")
            .eq("user_id", user.id);

          if (memberOrgsError) throw memberOrgsError;

          const memberOrgIds = memberOrgs.map((org) => org.organization_id);
          const { data: orgDetails, error: orgDetailsError } = await supabase
            .from("organizations")
            .select("*")
            .in("organization_id", memberOrgIds);

          if (orgDetailsError) throw orgDetailsError;

          setOrganizations([...ownedOrgs, ...orgDetails]);
        } catch (error) {
          console.error("Error fetching data:", error.message);
        }
      }
    };

    fetchData();
  }, [user]);

  // Updated toggleMenu function to allow multiple open menus
  const toggleMenu = (menu) => {
    setOpenMenus((prevOpenMenus) => ({
      ...prevOpenMenus,
      [menu]: !prevOpenMenus[menu],
    }));
  };

  // Handle "Create League" click
  const handleCreateLeague = () => {
    setShowCreateLeague(true);
    setShowCreateOrganization(false);
    setSelectedLeague(null);
    setSelectedOrganization(null);
  };

  // Handle "Create Organization" click
  const handleCreateOrganization = () => {
    setShowCreateOrganization(true);
    setShowCreateLeague(false);
    setSelectedLeague(null);
    setSelectedOrganization(null);
  };

  // Handle "Home" click
  const handleHomeClick = () => {
    setSelectedLeague(null);
    setSelectedOrganization(null);
    setShowCreateLeague(false);
    setShowCreateOrganization(false);
  };

  return (
    <div className="fantasy-page">
      {/* Navbar Section */}
      <nav className="navbar">
        <div className="logo">MILKYSPORTS</div>
        <ul className="sports-links">
          {["NFL", "NBA", "MLB", "NHL", "Soccer", "NCAA", "Fantasy"].map((item) => (
            <li key={item} className="dropdown">
              <button className="header-dropdown-button">{item}</button>
              <div className="dropdown-menu">
                <h4>{item} Options</h4>
                <ul>
                  <li>
                    <Link to={`/${item.toLowerCase()}/teams`}>Teams</Link>
                  </li>
                  <li>
                    <Link to={`/${item.toLowerCase()}/scores`}>Scores</Link>
                  </li>
                  <li>
                    <Link to={`/${item.toLowerCase()}/standings`}>Standings</Link>
                  </li>
                </ul>
              </div>
            </li>
          ))}
        </ul>
        <div className="auth-buttons">
          {user ? (
            <>
              <span className="username">Welcome, {user.username}</span>
              <button className="logout-button" onClick={handleLogout}>
                Log out
              </button>
            </>
          ) : (
            <>
              <button className="login-button" onClick={() => navigate("/login")}>Log in</button>
              <button className="signup-button" onClick={() => navigate("/create-account")}>Sign up</button>
            </>
          )}
        </div>
      </nav>

      {/* Sidebar Section */}
      <div className="custom-sidebar">
        <h3 className="section-header">Navigation</h3>
        <ul>
          <li onClick={handleHomeClick}>
            <FiHome className="icon" />
            Home
          </li>

          {/* Leagues Menu */}
          <li
            className={`dropdown ${openMenus["leagues"] ? "open" : ""}`}
            onClick={() => toggleMenu("leagues")}
          >
            <FiFileText className="icon" />
            <span>Leagues</span>
            <span className="arrow-icon">
              {openMenus["leagues"] ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          </li>
          {openMenus["leagues"] && (
            <ul className="sidebar-dropdown-menu">
              <li className="create-league" onClick={handleCreateLeague}>
                <FiPlus className="icon" />
                <span>Create League</span>
              </li>
              {Object.keys(leaguesBySport).map((sport) => (
                <div key={sport} className="sport-category">
                  <span className="sport-name">{sport}:</span>
                  <ul className="league-list">
                    {leaguesBySport[sport].map((league) => (
                      <li
                        key={league.league_id}
                        onClick={() => {
                          setSelectedLeague(league);
                          setShowCreateLeague(false);
                          setShowCreateOrganization(false);
                          setSelectedOrganization(null);
                        }}
                      >
                        {league.league_name}
                        {league.owner_id === user.id && <span className="owner-indicator"> (Owner)</span>}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          )}

          {/* Organizations Menu */}
          <li
            className={`dropdown ${openMenus["organizations"] ? "open" : ""}`}
            onClick={() => toggleMenu("organizations")}
          >
            <FiUsers className="icon" />
            <span>Organizations</span>
            <span className="arrow-icon">
              {openMenus["organizations"] ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </span>
          </li>
          {openMenus["organizations"] && (
            <ul className="sidebar-dropdown-menu">
              <li className="create-organization" onClick={handleCreateOrganization}>
                <FiPlus className="icon" />
                <span>Create Organization</span>
              </li>
              {organizations.length > 0 ? (
                organizations.map((org) => (
                  <div
                    key={org.organization_id}
                    className="organization-category"
                    onClick={() => {
                      setSelectedOrganization(org);
                      setShowCreateLeague(false);
                      setShowCreateOrganization(false);
                      setSelectedLeague(null);
                    }}
                  >
                    <span className="organization-name">{org.name}</span>
                  </div>
                ))
              ) : (
                <div className="no-organizations">No organizations found.</div>
              )}
            </ul>
          )}

          <li>
            <FiSettings className="icon" />
            Settings
          </li>
        </ul>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {!selectedLeague &&
        !showCreateLeague &&
        !selectedOrganization &&
        !showCreateOrganization ? (
          <div className="content">
            <h1>Welcome to MilkySports Fantasy!</h1>
            <p>Explore leagues, manage organizations, and compete with others in your fantasy sports journey!</p>
          </div>
        ) : showCreateLeague ? (
          <CreateFantasyLeague />
        ) : showCreateOrganization ? (
          <CreateOrganization />
        ) : selectedLeague ? (
          <FantasyLeagueManagement league={selectedLeague} />
        ) : selectedOrganization ? (
          <OrganizationManagement organization={selectedOrganization} user={user} />
        ) : null}
      </div>
    </div>
  );
};

export default Fantasy;

import React, { useState, useEffect } from "react";
import "./FantasyLeagueManagement.css";
import { FiSettings, FiUserPlus } from "react-icons/fi";
import { MdOutlineSportsFootball, MdLeaderboard } from "react-icons/md";
import { createClient } from "@supabase/supabase-js";
import { useUser } from "./UserContext";

// Supabase client initialization
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

const FantasyLeagueManagement = ({ league }) => {
  const { user } = useUser();
  const league_id = league ? league.league_id : null;

  const [activeTab, setActiveTab] = useState("overview");
  const [leagueMembers, setLeagueMembers] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [inviteLink, setInviteLink] = useState("");
  const [draftTime, setDraftTime] = useState("");
  const [draftScheduled, setDraftScheduled] = useState(false);
  const [draftInProgress, setDraftInProgress] = useState(false);
  const [draftedPlayers, setDraftedPlayers] = useState([]);
  const [availablePlayers, setAvailablePlayers] = useState([]);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [leagueName, setLeagueName] = useState("Loading...");
  const [sport, setSport] = useState("Loading...");
  const [owner, setOwner] = useState("Loading...");
  const [players, setPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [selectedPlayerStats, setSelectedPlayerStats] = useState(null);

  // Load activeTab from localStorage on mount
  useEffect(() => {
    const savedTab = localStorage.getItem("activeTab");
    if (savedTab) setActiveTab(savedTab);
  }, []);

  // Save activeTab to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    if (!league_id) return;

    const fetchLeagueData = async () => {
      try {
        const { data: leagueData, error } = await supabase
          .from("fantasy_leagues")
          .select("league_name, sport_id, owner_id")
          .eq("league_id", league_id)
          .single();

        if (error) throw error;

        setLeagueName(leagueData.league_name);
        setSport(leagueData.sport_id);

        const { data: ownerData, error: ownerError } = await supabase
          .from("profiles")
          .select("username")
          .eq("id", leagueData.owner_id)
          .single();

        if (ownerError) throw ownerError;

        setOwner(ownerData.username);

        const { data: members, error: membersError } = await supabase
          .from("league_members")
          .select(`
            user_id,
            joined_at,
            profiles(username)
          `)
          .eq("league_id", league_id);

        if (membersError) throw membersError;

        const membersFormatted = members.map((member) => ({
          name: member.profiles?.username || "Unknown",
          joinedAt: member.joined_at
            ? new Date(member.joined_at).getFullYear()
            : "Unknown",
        }));

        setLeagueMembers(membersFormatted);

        const activityMock = [
          "Joseph added Josh Allen to Milkanators.",
          "Landon traded Tyreek Hill to Waffle House.",
          "Derrick started Kenneth Walker III for Week 14.",
        ];
        setRecentActivity(activityMock);

        setInviteLink(`https://milkysports.com/join-league/${league_id}`);
      } catch (error) {
        console.error("Error fetching league data:", error.message);
      }
    };

    fetchLeagueData();
  }, [league_id]);

  useEffect(() => {
    if (!league_id) return;

    const fetchAllPlayers = async () => {
      try {
        const { data: playersData, error } = await supabase
          .from("nfl_players")
          .select("player_id, name");

        if (error) throw error;
        setAvailablePlayers(playersData);
      } catch (error) {
        console.error("Error fetching players for draft:", error.message);
      }
    };

    if (draftInProgress) {
      fetchAllPlayers();
    }
  }, [draftInProgress, league_id]);

  useEffect(() => {
    if (!league_id) return;

    const fetchMessages = async () => {
      try {
        const { data: chatMessages, error } = await supabase
          .from("league_chat")
          .select("*")
          .eq("league_id", league_id)
          .order("created_at", { ascending: true });

        if (error) throw error;

        setMessages(chatMessages);
      } catch (error) {
        console.error("Error fetching chat messages:", error.message);
      }
    };

    fetchMessages();

    const chatChannel = supabase
      .channel(`public:league_chat:league_id=eq.${league_id}`)
      .on(
        "postgres_changes",
        { event: "INSERT", schema: "public", table: "league_chat", filter: `league_id=eq.${league_id}` },
        (payload) => {
          setMessages((prev) => [...prev, payload.new]);
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(chatChannel);
    };
  }, [league_id]);

  const sendMessage = async () => {
    if (!newMessage.trim() || !user || !league_id) return;
    try {
      await supabase.from("league_chat").insert({
        league_id: league_id,
        user_id: user.id,
        message: newMessage,
      });
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error.message);
    }
  };

  const handlePlayerClick = async (playerId) => {
    try {
      const { data: statsData, error } = await supabase
        .from("nfl_playerstats")
        .select("*")
        .eq("player_id", playerId);

      if (error) throw error;

      setSelectedPlayerStats(statsData);
    } catch (error) {
      console.error("Error fetching player stats:", error.message);
    }
  };

  const scheduleDraft = async () => {
    if (!draftTime || !league_id) {
      alert("Please select a draft time.");
      return;
    }

    try {
      await supabase
        .from("drafts")
        .insert([{ league_id: league_id, draft_time: draftTime }]);

      alert(`Draft scheduled for ${new Date(draftTime).toLocaleString()}`);
      setDraftScheduled(true);
    } catch (error) {
      console.error("Error scheduling draft:", error.message);
    }
  };

  const startDraft = () => {
    if (!draftScheduled) {
      alert("Please schedule a draft first.");
      return;
    }
    setDraftInProgress(true);
    setDraftedPlayers([]);
    setCurrentTeamIndex(0);
  };

  const draftPlayer = (playerId) => {
    if (!draftInProgress) {
      alert("Draft is not in progress.");
      return;
    }

    const player = availablePlayers.find((p) => p.player_id === playerId);
    if (!player) {
      alert("Player not available.");
      return;
    }

    const currentTeam = leagueMembers[currentTeamIndex];
    setDraftedPlayers((prev) => [...prev, { player, team: currentTeam.name }]);

    setAvailablePlayers((prev) => prev.filter((p) => p.player_id !== playerId));

    setCurrentTeamIndex((prev) => (prev + 1) % leagueMembers.length);
  };

  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  const copyInviteLink = () => {
    navigator.clipboard.writeText(inviteLink);
    alert("Invite link copied to clipboard!");
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = players.filter((p) => p.name.toLowerCase().includes(query));
    setFilteredPlayers(filtered);
    setSelectedPlayerStats(null);
  };

  if (!user) {
    return <div>Please log in to participate in this league.</div>;
  }

  return (
    <div className="fantasy-league-management-container">
      {/* Header Section */}
      <header className="fantasy-league-management-header">
        <div className="fantasy-league-management-banner">
          <h1>{leagueName}</h1>
          <span className="fantasy-league-management-details">
            Sport: {sport} | Owner: {owner}
          </span>
        </div>
        <div className="invite-section">
          <button className="copy-link-button" onClick={copyInviteLink}>
            Copy Invite Link
          </button>
        </div>
      </header>

      {/* Navigation Tabs */}
      <nav className="fantasy-league-management-tabs">
        <button
          className={activeTab === "overview" ? "active" : ""}
          onClick={() => switchTab("overview")}
        >
          <MdOutlineSportsFootball /> Overview
        </button>
        <button
          className={activeTab === "players" ? "active" : ""}
          onClick={() => switchTab("players")}
        >
          Players
        </button>
        <button
          className={activeTab === "chat" ? "active" : ""}
          onClick={() => switchTab("chat")}
        >
          Chat
        </button>
      </nav>

      {/* Main Content */}
      <div className="fantasy-league-management-content">
        {activeTab === "overview" && (
          <div className="fantasy-league-management-overview-tab">
            <h2>Overview</h2>
            <p>Welcome to the Milky League dashboard! Manage your league here.</p>

            {/* Draft scheduling */}
            <h3>Schedule Draft</h3>
            {!draftScheduled ? (
              <div>
                <input
                  type="datetime-local"
                  value={draftTime}
                  onChange={(e) => setDraftTime(e.target.value)}
                />
                <button onClick={scheduleDraft}>Schedule Draft</button>
              </div>
            ) : (
              <p>Draft scheduled for {new Date(draftTime).toLocaleString()}</p>
            )}

            <h3>Start Draft</h3>
            {!draftInProgress ? (
              <button onClick={startDraft}>Start Draft</button>
            ) : (
              <div>
                <h4>Draft in Progress</h4>
                <ul>
                  {draftedPlayers.map((d, index) => (
                    <li key={index}>
                      {d.player.name} drafted by {d.team}
                    </li>
                  ))}
                </ul>
                <h5>Available Players</h5>
                <ul>
                  {availablePlayers.map((player) => (
                    <li
                      key={player.player_id}
                      onClick={() => draftPlayer(player.player_id)}
                      style={{ cursor: "pointer" }}
                    >
                      {player.name}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <h3>Recent Activity</h3>
            <ul className="recent-activity-list">
              {recentActivity.map((activity, index) => (
                <li key={index}>{activity}</li>
              ))}
            </ul>

            <h3>League Members</h3>
            <div className="league-members">
              {leagueMembers.map((member, index) => (
                <div key={index} className="league-member-card">
                  <div className="member-details">
                    <h4>{member.name}</h4>
                    <p>Since {member.joinedAt}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        {activeTab === "players" && (
          <div className="fantasy-league-management-players-tab">
            <h2>Players</h2>
            <input
              type="text"
              placeholder="Search players by name..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginBottom: "20px", padding: "10px", width: "100%" }}
            />

            <div className="players-list" style={{ display: "flex", gap: "20px" }}>
              <div
                className="player-names"
                style={{ flex: "1", maxHeight: "300px", overflowY: "auto" }}
              >
                {filteredPlayers.map((player, index) => (
                  <div
                    key={player.player_id}
                    style={{ cursor: "pointer", marginBottom: "10px", color: "#ccc" }}
                    onClick={() => handlePlayerClick(player.player_id)}
                  >
                    {index + 1}. {player.name}
                  </div>
                ))}
              </div>
              <div className="player-stats" style={{ flex: "2", color: "#ccc" }}>
                {selectedPlayerStats && selectedPlayerStats.length > 0 ? (
                  <div>
                    <h3>Player Stats</h3>
                    <table style={{ width: "100%", color: "#ccc" }}>
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Stat Name</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPlayerStats.map((statItem, idx) => (
                          <tr key={idx}>
                            <td>{statItem.stat_category}</td>
                            <td>{statItem.stat_name}</td>
                            <td>{statItem.stat_value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : selectedPlayerStats && selectedPlayerStats.length === 0 ? (
                  <p>No stats found for this player.</p>
                ) : (
                  <p>Select a player to view their stats.</p>
                )}
              </div>
            </div>
          </div>
        )}

        {activeTab === "chat" && (
          <div className="fantasy-league-management-chat-tab">
            <h2>League Chat</h2>
            <div className="chat-messages">
              {messages.map((msg) => (
                <p key={msg.message_id}>
                  <strong>{msg.user_id}:</strong> {msg.message}
                </p>
              ))}
            </div>
            <input
              type="text"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder="Type a message..."
            />
            <button onClick={sendMessage}>Send</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FantasyLeagueManagement;

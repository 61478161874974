import React, { useEffect, useState } from "react";
import Navbar from "./Navbar"; // Import the reusable Navbar component
import "./NFL.css"; // Import custom styles for the NFL page

const NFL = () => {
  const [liveScores, setLiveScores] = useState([]);
  const [newsArticles, setNewsArticles] = useState([]);
  const [standings, setStandings] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch data for live scores, news, standings, and schedule
  useEffect(() => {
    const fetchNFLData = async () => {
      try {
        // Fetch live scores
        const scoresResponse = await fetch("/api/nfl/scores");
        const scoresData = await scoresResponse.json();
        setLiveScores(scoresData);

        // Fetch news articles
        const newsResponse = await fetch("/api/nfl/news");
        const newsData = await newsResponse.json();
        setNewsArticles(newsData.articles);

        // Fetch standings
        const standingsResponse = await fetch("/api/nfl/standings");
        const standingsData = await standingsResponse.json();
        setStandings(standingsData);

        // Fetch schedule
        const scheduleResponse = await fetch("/api/nfl/schedule");
        const scheduleData = await scheduleResponse.json();
        setSchedule(scheduleData);
      } catch (error) {
        console.error("Error fetching NFL data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNFLData();
  }, []);

  return (
    <div className="nfl-page">
      {/* Navbar Section */}
      <Navbar /> {/* Reusable Navbar */}

      <div className="main-content">
        {/* Live Scores Section */}
        <section className="live-scores">
          <h2>Live Scores</h2>
          {loading ? (
            <p>Loading live scores...</p>
          ) : (
            <div className="score-cards">
              {liveScores.map((game, index) => (
                <div key={index} className="score-card">
                  <h3>
                    {game.team1} vs. {game.team2}
                  </h3>
                  <p>{game.score}</p>
                </div>
              ))}
            </div>
          )}
        </section>

        {/* Highlights Section */}
        <section className="highlights">
          <h2>Highlights</h2>
          <video
            controls
            width="100%"
            src="https://sample-videos.com/video123/mp4/720/big_buck_bunny_720p_1mb.mp4"
          >
            Your browser does not support the video tag.
          </video>
        </section>

        {/* News Section */}
        <section className="news">
          <h2>Recent News</h2>
          {loading ? (
            <p>Loading news...</p>
          ) : (
            <div className="news-cards">
              {newsArticles.map((article, index) => (
                <div key={index} className="news-card">
                  <h3>{article.title}</h3>
                  <p>{article.description}</p>
                  <a href={article.url} target="_blank" rel="noopener noreferrer">
                    Read More
                  </a>
                </div>
              ))}
            </div>
          )}
        </section>

        {/* Standings Section */}
        <section className="standings">
          <h2>Standings</h2>
          {loading ? (
            <p>Loading standings...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Rank</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((team, index) => (
                  <tr key={index}>
                    <td>{team.name}</td>
                    <td>{team.wins}</td>
                    <td>{team.losses}</td>
                    <td>{team.rank}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </section>

        {/* Schedule Section */}
        <section className="schedule">
          <h2>Upcoming Games</h2>
          {loading ? (
            <p>Loading schedule...</p>
          ) : (
            <ul>
              {schedule.map((game, index) => (
                <li key={index}>
                  {game.date}: {game.team1} vs. {game.team2}
                </li>
              ))}
            </ul>
          )}
        </section>
      </div>
    </div>
  );
};

export default NFL;
